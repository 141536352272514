.inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 52px;
}

@media (max-width: 750px) {
  .inputs {
    flex-direction: column;
    margin: 0;
    width: 100%
  }
}

.submit-button {
  display: flex;
  margin: auto 100px;
  height: max-content;
}

@media (max-width: 750px) {
  .submit-button {
    margin: 0;
    justify-content: center;
  }
}