.regex-highlighter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 300px;
}

@media (max-width: 750px) {
  .regex-highlighter-container {
    min-width: 0;
  }
}

.regex-highlighter {
  box-sizing: border-box;
  width: fit-content;
  min-width: 250px;
  padding: 8px;
  border: 2px solid #333;
  border-radius: 3px;
  text-align: center;
  margin: 12px;
  min-width: 450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 750px) {
  .regex-highlighter {
    width: 100%;
    min-width: 0;
  }
}

.slash-left {
  margin-left: 8px;
  font-weight: bold;
}

.slash-right {
  margin-right: 8px;
  font-weight: bold;
}

.regex-text {
  font-family: monospace;
  font-size: 13.333px;
}

@media (max-width: 750px) {
  .regex-text {
    font-size: 10px;
  }
}
.regex-highlighter__caret {
  color: purple;
}

.regex-highlighter__dollar {
  color: purple;
}

.regex-highlighter__dot {
  color: red;
}

.regex-highlighter__asterisk {
  color: red;
}

.regex-highlighter__plus {
  color: red;
}

.regex-highlighter__question {
  color: red;
}

.regex-highlighter__curly-brace-open {
  color: blue;
}

.regex-highlighter__curly-brace-close {
  color: blue;
}

.regex-highlighter__paren-open {
  color: green;
}

.regex-highlighter__paren-close {
  color: green;
}

.regex-highlighter__square-brace-open {
  color: blue;
}

.regex-highlighter__square-brace-close {
  color: blue;
}

.regex-highlighter__backslash {
  color: purple;
}

.regex-highlighter__pipe {
  color: red;
}

.loading {
  font-size: 30px;
}

.loading:after {
  content: "...";
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis-dot 1s infinite 0.3s;
  animation-fill-mode: forwards;
  width: 1.25em;
}

@keyframes ellipsis-dot {
  25% {
    content: "";
  }
  50% {
    content: ".";
  }
  75% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
