.container {
  width: 40%;
  display: flex;
  flex-direction: column;
  max-width: 750px;
}

@media (max-width: 750px) {
  .container {
    width: 100%
  }
}

.caption {
  margin: 0;
  display: flex;
  flex-flow: column;
  text-align: end;
  font-size: 8px;
  font-weight: 100;
}

.tags-input-container {
  border: 2px solid #000;
  padding: 0.5em;
  border-radius: 3px;
  margin-top: 2px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  flex-direction: column;
  min-height: 300px;
}

@media (max-width: 750px) {
  .tags-input-container {
    min-height: 200px;
  }
}

.tag-item {
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: row;
  padding: 0.5em 0.75em;
  border-radius: 20px;
  white-space: pre-wrap;
  word-break: break-word;
  align-items: center;
  width: fit-content;
  font-size: 13.333px;
  color: rgba(0, 0, 0, 0.87);
}
.tag-item .close {
  height: 17px;
  width: 17px;
  -webkit-tap-highlight-color: transparent;
  background-color: rgba(0, 0, 0, 0.26);
  color: white;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 20px;
  cursor: pointer;
}

.tag-item .close:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.textarea-container .add {
  height: 20px;
  width: 20px;
  margin-top: 6px;
  -webkit-tap-highlight-color: transparent;
  background-color: #00b248;
  color: white;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  opacity: 0.6;
}

.textarea-container .add:hover {
  background-color: #007e33;
}

.tags-input {
  padding: 0.5em 0;
  border: none;
  outline: none;
  width: 100%;
  resize: none;
}
