.stream-text {
  margin: 0 52px;
  min-height: 76px;
  font-family: Söhne, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto,
    Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica Neue, Arial,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 12px;
  font-weight: 400;
}

@media (max-width: 750px) {
  .stream-text {
    margin: 0 12px;
    min-height: 98px;
  }
}

/* blinking cursor */
.cursor {
  line-height: 17px;
  margin-left: 3px;
  animation: blink 0.5s infinite;
  display: inline;
}

@keyframes blink {
  0% {
    background: transparent;
  }
  50% {
    background: black;
  }
  100% {
    background: transparent;
  }
}
